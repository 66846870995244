import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  tags: [],
  IssueItemTags: [],
  tagsStatus: "idle",
  status: "idle",
  error: null,
};

export const getAllTags = createAsyncThunk(
  "tag/getAllTags",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(`/websites/${websiteID.websiteID}/tags`);
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

// create tag
export const createTag = createAsyncThunk("tag/createTag", async (data) => {
  let response;
  try {
    response = await axios.post(`/websites/${data.websiteID}/tags`, {
      name: data?.name,
    });
    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : response?.data?.message);
  }
});

const slice = createSlice({
  name: "conversation",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllTags.pending]: (state) => {
      state.tagsStatus = "loading";
    },
    [getAllTags.fulfilled]: (state, action) => {
      state.tagsStatus = "succeeded";
      state.tags = action.payload;
    },
    [getAllTags.rejected]: (state, action) => {
      state.tagsStatus = "failed";
      state.error = action.payload;
    },

    [createTag.pending]: (state) => {
      state.status = "loading";
    },
    [createTag.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.tags = [...state.tags, action.payload];
    },
    [createTag.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export default slice;
